import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { getSession } from './views/utils/useSessionStorage'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./Login'))
class App extends Component {
  render() {
    const auth = JSON.parse(getSession('isAuthenticated'))

    //console.log(auth)

    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              path="/"
              name="Home"
              render={(props) => (auth ? <DefaultLayout {...props} /> : <Redirect to="/" />)}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
